import * as React from 'react'
import Layout from '../../layout'
import LinkRFIDPage from '../../pagesComponents/rfid/index'

const LinkRFID = ({ location }) => {
	const params = new URLSearchParams(location.search)
	const cardId = params.get('cardId')?.toString() || null
	return (
		<Layout title="Link Your Wristband">
			<LinkRFIDPage cardId={cardId} />
		</Layout>
	)
}

export default LinkRFID
