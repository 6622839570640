import React, { useEffect, useRef } from 'react'
import { Button, Text, useToast } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import BasePage from '../../components/basePage'
import Accordion from '../../components/accordion'
import { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import Spinner from '../../components/spinner'
import { getRFID, removeRFID, setDefaultGroup } from '../../helpers/database'
import { errorToastOptions } from '../../helpers'
import { updateRFID, validateShortCode } from '../../services/rfid'
import { useAuth } from '../../hooks/useAuth'
import { getMyGroups } from '../../services/group'

const SelectGroupPage = ({ cardId, setGroup }) => {
	const rfid = getRFID()
	const toast = useToast()
	const { refreshProfile } = useAuth()
	const [selected, setSelected] = useState(-1)
	const isMounted = useRef(false)

	const { data, isLoading } = useQuery({
		queryKey: ['groups'],
		queryFn: getMyGroups,
		select: (data) => {
			const filteredData = data
				.filter((group) => {
					const today = Date.parse(
						new Date().toISOString().split('T')[0]
					)
					const eventDate = Date.parse(group.eventDate)
					return eventDate >= today
				})
				.sort((a, b) => {
					const dateA = new Date(a.eventDate)
					const dateB = new Date(b.eventDate)
					return dateA - dateB
				})
			return filteredData
		}
	})

	useEffect(() => {
		if (data && data.length === 1) {
			setSelected(0)
		}
	}, [data])

	const mutation = useMutation(updateRFID, {
		onSuccess: async (data) => {
			// console.log('updateRFID', data)
			removeRFID()
			await refreshProfile()

			await navigate(`/leaderboards/${data?.groupId}?cardId=${cardId}`)

			// await navigate('/rfid/confirm/')
		},
		onError: (error) => {
			// console.log('updateRFID', error)
			if (isMounted.current) {
				navigate(`/rfid`)
				if (typeof setGroup === 'function') {
					setGroup(data[selected || 0]?.id)
				}
				toast({
					description:
						error.message || 'Not a valid rfid wristband' || error,
					...errorToastOptions
				})
			}
		}
	})

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	const handleSelectGroup = async (group) => {
		if (!cardId) {
			if (typeof setGroup === 'function') {
				setGroup(data[selected || 0]?.id)
			}
			setDefaultGroup(data[selected || 0]?.id)
			navigate(`/rfid?group=${data[selected || 0]?.id}`)
			return
		}

		// sets the default group on the Leaderboard menu
		// in-venue iPad this doesn't work due to device change

		// const result = await validateShortCode({ code: cardId })

		if (data[selected]?.id) {
			mutation.mutate({
				groupId: data[selected]?.id,
				rfid: `${cardId}`
			})
		}
	}

	return (
		<BasePage
			theme="dark"
			backBtn="/"
			largeTitle={
				<>
					Select
					<br />
					Group
				</>
			}
			primaryBtn={
				<Button
					variant="primary"
					isLoading={mutation.isLoading}
					disabled={isLoading || mutation.isLoading || selected < 0}
					onClick={handleSelectGroup}
				>
					Confirm
				</Button>
			}
		>
			<Text vairant="m" color="white" mt="40px" textAlign="center">
				If you have multiple groups you can choose which one to link
				your RFID wristband to using the dropdown below.
			</Text>
			{isLoading && <Spinner mb="12px" mt="40px" />}
			{!isLoading && (
				<Accordion
					mt="40px"
					val={selected}
					options={data || []}
					onSelect={setSelected}
					hideDate={true}
				/>
			)}
		</BasePage>
	)
}

export default SelectGroupPage
