import * as React from 'react'
import BasePage from '../../components/basePage'
import { Button, Flex, Text, useToast, Container, Box } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { useForm } from 'react-hook-form'
import { useEffect, useState, useRef } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import { updateRFID, validateShortCode } from '../../services/rfid'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { object, string } from 'yup'
import { removeRFID, setRFID, setDefaultGroup } from '../../helpers/database'

import RfidCard from '../../icons/rfid-card.svg'

import { navigate } from 'gatsby'

import RFIDConnectStatus from '../../components/RFIDConnectStatus'
import { errorToastOptions } from '../../helpers'

import { useAuth } from '../../hooks/useAuth'
import Input from '../../components/input'
import eventTracking from '../../services/eventTracking'
import { getGroupByRfid, getGroup, getGroupMembers } from '../../services/group'
import SelectGroupPage from './group'
import QRScanner from '../../components/QRScanner'
import Spinner from '../../components/spinner'

import { getMyGroups } from '../../services/group'
import RFIDLinkButton from '../../components/RFIDLinkButton'
import NestedNote from '../../components/nested-note'

const LinkRFIDPage = ({ cardId }) => {
	const { profile, refreshProfile, user } = useAuth()
	const [validating, setValidate] = useState(false)
	const toast = useToast()
	const isMounted = useRef(false)
	const [updateRfidFailed, setUpdateRfidFailed] = useState(false)
	const [cameraDenied, setCameraDenied] = useState(false)

	// const groupByRFIDQuery = useQuery(['rfid', profile?.rfid], getGroupByRfid, {
	// 	enabled: !!profile?.rfid
	// })

	const params = new URLSearchParams(window.location.search)
	const [groupId, setGroupId] = useState(
		parseInt(params.get('group')) || null
	)

	const groupByIdQuery = useQuery(
		['group', groupId, { userId: user?.uid }],
		getGroup,
		{
			enabled: !!groupId
		}
	)

	const userGroupsData = useQuery({
		queryKey: ['groups'],
		queryFn: getMyGroups
	})

	const groupMembersQuery = useQuery(
		['groupMembers', groupId],
		getGroupMembers,
		{ enabled: !!groupId }
	)

	const rfid =
		groupMembersQuery?.data?.find((member) => member.id === user.uid)
			?.rfid || false

	// const groupData = groupByIdQuery.data
	// 	? groupByIdQuery.data
	// 	: groupByRFIDQuery.data
	// 	? groupByRFIDQuery.data
	// 	: null

	const resolver = yupResolver(
		object()
			.shape({
				code: string().trim().required().min(4)
			})
			.required()
	)
	const {
		watch,
		register,
		handleSubmit,
		getValues,
		setError,
		clearErrors,
		setValue,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false
	})
	const watchCode = watch('code')
	const isValid = !!watchCode
	const doLoader = useRef()

	const { fetchStatus, data, isFetching } = useQuery(
		['code', watchCode?.trim().toUpperCase()],
		validateShortCode,
		{
			enabled: validating && !!watchCode?.trim()
		}
	)

	const userGroupsFuture = React.useMemo(() => {
		return (userGroupsData?.data || []).filter(
			(group) => !group.isPastGroup
		)
	}, [userGroupsData?.data])

	useEffect(() => {
		if (!userGroupsData.isLoading && userGroupsFuture.length === 1) {
			// Only 1 group selected
			setGroupId(userGroupsFuture[0].id)

			setTimeout(() => {
				if (cardId && !doLoader.current) {
					onSubmit({
						cardId: cardId,
						groupId: userGroupsFuture[0].id
					})

					doLoader.current = true
				}
			}, 100)
		}

		if (!userGroupsData.isLoading && userGroupsFuture.length === 0) {
			// No group available
			navigate('/groups')
		}
	}, [userGroupsData, userGroupsFuture, cardId])

	const mutation = useMutation(updateRFID, {
		onSuccess: async (data) => {
			await refreshProfile()
			setDefaultGroup(groupId)
			await navigate(`/leaderboards/${groupId}?cardId=${data.rfid}`)
			removeRFID()
		},
		onError: (error) => {
			setUpdateRfidFailed(true)
			if (isMounted.current) {
				// Only show when still on this page
				toast({
					description: error.message || error,
					...errorToastOptions
				})
			}
		}
	})

	const onValidateCode = async () => {
		const data = getValues()
		if (!data?.code) return
		setValidate(true)
	}

	const onSubmit = (data) => {
		setRFID(data)

		refreshProfile()
		eventTracking('sucess_RFID_read', {
			user_id: profile?.id,
			RFID: data?.code
		})

		if (data.groupId || groupId) {
			mutation.mutate({
				groupId: data.groupId || groupId,
				rfid: data.cardId
			})
		} else {
			// navigate('/rfid/group')
		}
	}

	const onNewScanResult = (decodedText) => {
		// handle decoded results here

		let code
		if (decodedText.toString().includes('http')) {
			code = decodedText.split('cardid=')[1]
		} else {
			code = decodedText
		}

		code = code.replace('<', '').replace('>', '')

		setValue('code', code)
		onValidateCode()
	}

	useEffect(() => {
		if (isFetching) {
			clearErrors('code')
			setValidate(false)
		} else {
			if (!!data?.cardId) {
				clearErrors('code')
				setValidate(false)
				onSubmit(data)
			} else if (!!data) {
				setValidate(false)
				setError('code', {
					type: 'custom',
					message: 'Oh no! That code is invalid'
				})
				eventTracking('errors_RFID_read', {
					user_id: profile?.id,
					RFID: watchCode
				})
			}
		}
	}, [data, fetchStatus, isFetching])

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	if (userGroupsData.isLoading) {
		return <Spinner />
	}

	if (!userGroupsData.isLoading && userGroupsFuture?.length > 1 && !groupId) {
		setRFID({ cardId })
		return (
			<SelectGroupPage
				cardId={cardId}
				setGroup={(gId) => setGroupId(gId)}
			/>
		)
	}

	if (cardId && updateRfidFailed) {
		return <Spinner />
	}

	return (
		<form onSubmit={handleSubmit(onValidateCode)}>
			<BasePage
				theme="dark"
				backBtn={'/'}
				primaryBtn={
					<Button
						variant="primary"
						isLoading={isFetching || userGroupsData.isLoading}
						disabled={!isValid || isFetching}
						type="submit"
					>
						Continue
					</Button>
				}
				rightBtn={
					rfid ? (
						<RFIDLinkButton groupId={groupId} rfid={rfid} />
					) : null
				}
			>
				{/* {window?.location?.search.indexOf('group') < 0 &&
				groupByRFIDQuery?.data?.id ? (
					<>
						<Text
							color="white"
							mt={4}
							mb="-8px"
							variant="lg"
							textAlign={'center'}
						>
							Connected RFID:
						</Text>
						<Container
							variant="note"
							style={{
								backgroundImage:
									'url(/images/transparent-note.svg)',
								backgroundSize: '100% 100%'
							}}
						>
							<Flex
								alignItems={'center'}
								direction="column"
								justifyContent="center"
							>
								<Flex direction="row">
									<CheckGreenSVG />
									<Text color="white" ml="1" variant="lg">
										{profile?.shortCode || '......'}
									</Text>
								</Flex>
								{groupByRFIDQuery?.data && (
									<>
										<Text color="white" mt="1">
											Connected to
										</Text>
										<Text color="white" fontWeight={900}>
											{groupByRFIDQuery?.data.name}
										</Text>
									</>
								)}
							</Flex>
						</Container>
					</>
				) : (
					<Flex justifyContent="center" alignItems="center" mt="40px">
						<RfidCard />
					</Flex>
				)} */}

				{groupByIdQuery?.data?.name && (
					<NestedNote
						outerText="You're in"
						innerText={groupByIdQuery?.data?.name}
					/>
				)}

				<Flex mb="16px">
					<Flex
						justifyContent="center"
						alignItems="center"
						// my="20px"
					>
						{/* <RfidCard width="80px" height="80px" my={0} /> */}
						<StaticImage
							style={{ display: 'block', width: '80px' }}
							src="../../images/wrist.png"
						/>
					</Flex>
					{rfid ? (
						<Text
							as="h1"
							color="white"
							style={{ textAlign: 'left' }}
							fontSize={'36px !important'}
						>
							<span style={{ whiteSpace: 'nowrap' }}>
								Link new
							</span>
							<br />
							<span style={{ whiteSpace: 'nowrap' }}>
								wristband
							</span>
						</Text>
					) : (
						<Text
							as="h1"
							color="white"
							style={{ textAlign: 'left' }}
							fontSize={'36px !important'}
						>
							<span style={{ whiteSpace: 'nowrap' }}>
								Now link
							</span>
							<br />
							<span style={{ whiteSpace: 'nowrap' }}>your</span>
							<br />
							<span style={{ whiteSpace: 'nowrap' }}>
								wristband
							</span>
						</Text>
					)}
				</Flex>

				{!cameraDenied ? (
					<Container
						maxWidth={'80%'}
						pt={'70%'}
						position={'relative'}
					>
						<Container
							position={'absolute'}
							top={0}
							left={0}
							height={'100%'}
							width={'100%'}
						>
							{!userGroupsData.isLoading && (
								<QRScanner
									qrCodeSuccessCallback={onNewScanResult}
									handleCameraNotEnabled={() =>
										setCameraDenied(true)
									}
								/>
							)}
						</Container>
					</Container>
				) : (
					<>
						<Text variant="m" color="pink" textAlign="center">
							Camera access denied. Please <br /> allow access and
							refresh this page or enter the wristband number
							below.
						</Text>
					</>
				)}
				<Input
					name="code"
					type="code"
					variant="filled"
					placeholder="RFID Code"
					mt="24px"
					register={register}
					errors={errors}
				/>
				<Text variant="m" mt="10px" color="white" textAlign="center">
					Tip: You can find your RFID code below the QR code on your
					wristband.
				</Text>
			</BasePage>
		</form>
	)
}

export default LinkRFIDPage
