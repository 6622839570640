import React from 'react'
import { Text, Icon, Button, Flex } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import CheckGreenSVG from '../icons/check-green.svg'

const RFIDLinkButton = ({ groupId, rfid }) => {
	return (
		<Button
			h="48px"
			pl="14px"
			pr="13px"
			direction="row"
			alignItems="center"
			backgroundColor="darkGray"
			borderRadius="200px"
			onClick={() => {
				navigate(`/rfid?group=${groupId}`)
			}}
		>
			{!rfid || rfid === 'undefined' ? (
				<>
					<Icon
						width="26px"
						height="19px"
						viewBox="0 0 26 19"
						fill="none"
						mr="5px"
					>
						<path
							d="M11.7655 10.5385C11.5601 10.3524 11.3953 10.128 11.2811 9.87866C11.1668 9.62933 11.1054 9.36017 11.1004 9.08725C11.0955 8.81433 11.1471 8.54323 11.2522 8.29013C11.3574 8.03704 11.5139 7.80712 11.7124 7.61411C11.9109 7.42109 12.1474 7.26893 12.4077 7.1667C12.668 7.06447 12.9468 7.01427 13.2275 7.01908C13.5082 7.0239 13.785 7.08363 14.0415 7.19473C14.2979 7.30582 14.5287 7.466 14.7201 7.6657C15.0894 8.05102 15.2904 8.56066 15.2808 9.08725C15.2713 9.61384 15.0519 10.1163 14.6689 10.4887C14.2858 10.8611 13.7691 11.0744 13.2275 11.0837C12.6859 11.093 12.1618 10.8975 11.7655 10.5385ZM3.87859 0L5.85066 1.91744C3.89066 3.82336 2.78957 6.40822 2.78957 9.10345C2.78957 11.7987 3.89066 14.3835 5.85066 16.2895L3.87859 18.2069C2.64894 17.0115 1.67351 15.5923 1.00802 14.0303C0.342527 12.4683 0 10.7942 0 9.10345C0 7.41275 0.342527 5.7386 1.00802 4.17662C1.67351 2.61464 2.64894 1.19542 3.87859 0ZM22.6042 0C23.8338 1.19542 24.8092 2.61464 25.4747 4.17662C26.1402 5.7386 26.4828 7.41275 26.4828 9.10345C26.4828 10.7942 26.1402 12.4683 25.4747 14.0303C24.8092 15.5923 23.8338 17.0115 22.6042 18.2069L20.6321 16.2895C22.5921 14.3835 23.6932 11.7987 23.6932 9.10345C23.6932 6.40822 22.5921 3.82336 20.6321 1.91744L22.6042 0ZM7.82133 3.83218L9.79201 5.74826C9.339 6.18868 8.97965 6.71154 8.73448 7.28699C8.48931 7.86245 8.36312 8.47922 8.36312 9.10209C8.36312 9.72497 8.48931 10.3417 8.73448 10.9172C8.97965 11.4926 9.339 12.0155 9.79201 12.4559L7.82133 14.372C6.38389 12.9743 5.57634 11.0787 5.57634 9.10209C5.57634 7.1255 6.38389 5.22987 7.82133 3.83218ZM18.6614 3.83218C20.0989 5.22987 20.9064 7.1255 20.9064 9.10209C20.9064 11.0787 20.0989 12.9743 18.6614 14.372L16.6908 12.4559C17.1438 12.0155 17.5031 11.4926 17.7483 10.9172C17.9935 10.3417 18.1196 9.72497 18.1196 9.10209C18.1196 8.47922 17.9935 7.86245 17.7483 7.28699C17.5031 6.71154 17.1438 6.18868 16.6908 5.74826L18.6614 3.83218Z"
							fill="white"
						/>
					</Icon>
					<Text variant="ml" fontWeight="900" color="white" mt="1px">
						Link RFID
					</Text>
				</>
			) : (
				<Flex direction="row">
					<CheckGreenSVG />
					<Text color="white" ml="1" variant="lg">
						{rfid || ''}
					</Text>
				</Flex>
			)}
		</Button>
	)
}

export default RFIDLinkButton
